//import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
// import { initializeApp } from "https://www.gstatic.com/firebasejs/10.3.1/firebase-app.js";
// import { getAnalytics } from "https://www.gstatic.com/firebasejs/10.3.1/firebase-analytics.js";
// import { getAuth } from "https://www.gstatic.com/firebasejs/10.3.1/firebase-auth.js";
// import { getDatabase } from "https://www.gstatic.com/firebasejs/10.3.1/firebase-database.js";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
//https://firebase.google.com/docs/web/learn-more?authuser=0#libraries-cdn

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDaj7wm8n-spQjxB_pCL5YmSNCKDIJfFgw",
  authDomain: "soccercam-59ce6.firebaseapp.com",
  projectId: "soccercam-59ce6",
  storageBucket: "soccercam-59ce6.appspot.com",
  messagingSenderId: "795216155821",
  appId: "1:795216155821:web:3d05c4c14807c0d8dce224",
  measurementId: "G-YPZB2B6S9B",
};
let app, analytics, auth, database;
// Initialize Firebase
function init() {
  app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
  auth = getAuth(app);
  database = getDatabase(app);
}

init();

export const getApp = app;
export const initAnalytics = analytics;
export const initAuth = auth;
export const db = database;
export const storage = getStorage(app);
